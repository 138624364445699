export const technologies: string[] = [
	"Javascript",
	"AWS",
	"GO",
	"Typescript",
	"Solidity",
	"Node.js",
	"React.js",
	"Docker",
	"MongoDB",
	"Kafka",
	"Redis",
]
