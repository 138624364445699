import { CodesandBox, GithubSvg, Instagram, Linkedin, Twitter } from "../icons"
import { Findme } from "./findme"
import { Footer } from "./footer"

export const Intro: React.FC = () => {
	return (
		<>
			<div className='intro'>
				<p>Hey, My name is</p>
				<h1>Asim Hafeez.</h1>
				<p>& I like to know</p>
				<h2>How things work?</h2>
				<p>Currently learning Distributed Systems!</p>

				<a href='mailto:asimhafeezz@icloud.com'>
					<>Say Hello</>
				</a>
				{/* <Findme />
				<Footer /> */}
			</div>
			<></>
		</>
	)
}
